<template>
  <v-sheet
    class="xflex xw-full md:xflex-row xflex-col xgap-[3px] xjustify-between xitems-center"
  >
    <div
      :class="[mdUp ? 'xflex-row' : 'xflex-col']"
      class="controller xflex xgap-[5px] xjustify-start xitems-center xw-full"
    >
      <app-search
        :api="`api/projects/search`"
        placeholder="Search project ..."
        @selected="onSelect"
        item-text="name"
        :class="[laptopUp ? '!xmax-w-[300px]' : 'xw-full']"
        item-value="id"
      ></app-search>

      <KirbyDatePicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-if="type === 'daily'"
      ></KirbyDatePicker>

      <KirbyWeeklyPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'weekly'"
      ></KirbyWeeklyPicker>

      <KirbyMonthPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'monthly'"
      ></KirbyMonthPicker>
      <div
        class="xflex flex-row xgap-[5px] md:xmy-0 xmy-[10px] typer"
        v-if="laptopUp"
      >
        <btn-tooltip
          tip="Filter by date"
          :color="type === 'daily' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'daily'"
          :loading="fetchingCsv"
          :outlined="type !== 'daily'"
          @click="setFilter('daily')"
          class="text-none"
        >
          Day
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by weekly"
          :color="type === 'weekly' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'weekly'"
          :loading="fetchingCsv"
          :outlined="type !== 'weekly'"
          @click="setFilter('weekly')"
          class="text-none"
        >
          Week
        </btn-tooltip>
        <btn-tooltip
          tip="Filter by monthly"
          :color="type === 'monthly' ? '#7A38A3' : 'base_100'"
          :disabled="fetchingCsv || type === 'monthly'"
          :loading="fetchingCsv"
          :outlined="type !== 'monthly'"
          @click="setFilter('monthly')"
          class="text-none"
        >
          Month
        </btn-tooltip>
      </div>
      <div
        class="xflex xflex-row xjustify-end xitems-center xgap-[5px] xw-full !xmy-[5px]"
        v-else
      >
        <btn-range-filter
          @monthly="setFilter('monthly')"
          @weekly="setFilter('weekly')"
          @daily="setFilter('daily')"
          no-custom
          :type="type"
        >
        </btn-range-filter>
        <v-spacer></v-spacer>
        <btn-tooltip
          v-if="$can.and('manage_projects')"
          tip="Filter by date"
          color="primary"
          class="text-none"
          @click="openModal"
        >
          <v-icon left>mdi-plus</v-icon> Add Project
        </btn-tooltip>
      </div>
    </div>
    <div
      v-if="laptopUp"
      class="xflex xgap-[0.5em] xflex-row xjustify-end xitems-center"
    >
      <btn-tooltip
        v-if="$can.and('manage_projects')"
        tip="Filter by date"
        color="primary"
        class="text-none"
        @click="openModal"
        :block="smDown"
      >
        <v-icon left>mdi-plus</v-icon> Add Project
      </btn-tooltip>
      <slot name="extra-buttons"></slot>
    </div>

    <ProjectModal
      v-model="modal"
      :project="project"
      @new-item="$emit('refetch')"
      @update-item="$emit('refetch')"
    ></ProjectModal>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import KirbyWeeklyPicker from "@/views/GlobalComponents/Forms/KirbyWeeklyPicker.vue";
import KirbyDatePicker from "@/views/GlobalComponents/Forms/KirbyDatePicker.vue";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";
import ProjectModal from "@/views/GlobalComponents/Modals/ProjectModal.vue";
export default {
  props: {
    type: { type: String, default: "daily" },
    value: { type: [Object, Array, String], default: undefined },
    hasDownload: { type: Boolean, default: true },
  },
  data() {
    return {
      modal: false,
      fetchingCsv: false,
      selectedValue: null,
      project: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedValue = val;
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    csvName() {
      if (this.type === "monthly")
        return `${this.user.team.name}-Monthly-${this.value}`;
      else if (this.type === "weekly")
        return `${this.user.team.name}-Weekly-${this.value[0]}-${this.value[1]}`;
      else if (this.type === "daily")
        return `${this.user.team.name}-Daily-${this.value}`;
      else
        return `${this.user.team.name}-Custom-${this.value[0]}-${this.value[1]}`;
    },
    csvUrl() {
      if (this.type === "monthly")
        return `api/team-records/monthly/${this.value}/null/csv`;
      else if (this.type === "weekly")
        return `api/team-records/weekly/${this.value[0]}/${this.value[1]}/csv`;
      else if (this.type === "daily")
        return `api/team-records/daily/${this.value}/null/csv`;
      else
        return `api/team-records/custom/${this.value[0]}/${this.value[1]}/csv`;
    },
  },
  created() {
    this.$event.$on("edit-project", (project) => {
      this.project = project;
      this.modal = true;
    });
  },
  methods: {
    onSelect(val) {
      this.$router.push({
        name: "app-project-monthly",
        params: { project_id: val.id, year_month: moment().format("YYYY-MM") },
      });
    },
    openModal() {
      this.modal = true;
      this.project = null;
    },
    fetchCsv() {
      this.fetchingCsv = true;
      this.$axios
        .get(this.csvUrl, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.csvName}.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appToast("No record found.", "error");
        })
        .finally(() => (this.fetchingCsv = false));
    },
    emitChange(val) {
      this.$emit("change", val);
    },
    emitSearch(val) {
      this.$emit("search", { keyword: val, value: this.value });
    },
    setFilter(type) {
      if (type === "monthly")
        this.$router.push({
          name: "app-project-report-monthly",
          params: { year_month: moment().format("YYYY-MM") },
        });
      else if (type === "weekly")
        this.$router.push({
          name: "app-project-report-weekly",
          params: {
            start: moment().startOf("week").format("YYYY-MM-DD"),
            end: moment().endOf("week").format("YYYY-MM-DD"),
          },
        });
      else if (type === "daily")
        this.$router.push({
          name: "app-project-report-daily",
          params: { date: moment().format("YYYY-MM-DD") },
        });
    },
  },
  components: {
    KirbyWeeklyPicker,
    KirbyDatePicker,
    KirbyMonthPicker,
    ProjectModal,
  },
};
</script>

<style lang="css">
.typer .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: #7a38a3 !important;
  color: #fff !important;
}
</style>
