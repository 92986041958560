<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center">
      <Filters
        :search="search"
        :value="selectedRange"
        @change="navigate"
        @refetch="fetchProjects(selectedRange)"
        type="weekly"
      >
      </Filters>
    </v-card-title>
    <v-card-text>
      <ProjectTable
        :loading="loading"
        :loading-more="loading_more"
        :items="items"
        :empty-text="`No projects found for <br/>${dateHuman}`"
      >
      </ProjectTable>
    </v-card-text>
    <v-card-actions
      v-if="next_page_url"
      :class="[mdUp ? 'xabsolute xbottom-0 xh-[60px]' : '']"
      class="xw-full xflex xflex-col xjustify-center xitems-center"
    >
      <btn-tooltip
        tip="Load more"
        color="primary"
        outlined
        @click="fetchMore"
        :loading="loading_more"
        :disabled="loading_more"
      >
        Load more
      </btn-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import ProjectTable from "../components/ProjectTable.vue";
export default {
  data() {
    return {
      tab: "projects",
      loading: false,
      loading_more: false,
      items: [],
      headers: [
        { text: "Project Name", value: "", sortable: false },
        { text: "Admin", value: "", sortable: false },
        { text: "Users", value: "", sortable: false },
        { text: "Total Time Spent", value: "", sortable: false },
        { text: "Created", value: "", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      selectedRange: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD"),
      ],
      next_page_url: null,
    };
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().startOf("week").format("YYYY-MM-DD"),
              moment().endOf("week").format("YYYY-MM-DD"),
            ];
        val && this.fetchProjects(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    dateHuman() {
      if (!this.selectedRange)
        return `${moment().startOf("week").format("LL")} - ${moment()
          .endOf("week")
          .format("LL")}`;
      return `${moment(this.selectedRange[0], "YYYY-MM-DD").format(
        "LL"
      )} - ${moment(this.selectedRange[1], "YYYY-MM-DD").format("LL")}`;
    },
  },
  mounted() {
    this.$event.$on("remove-project", (project) => {
      let index = this.items.findIndex((i) => i.id === project.id);
      if (~index) this.items.splice(index, 1);
    });
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-project-report-weekly",
          params: { start: val[0], end: val[1] },
        })
        .catch(() => {});
    },
    fetchProjects(range) {
      this.loading = true;
      this.$axios
        .get(`api/projects/weekly/${range.start}/${range.end}`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
          this.$event.$emit("project-report-count", this.items.length);
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
          this.$event.$emit("project-report-count", this.items.length);
        })
        .finally(() => (this.loading_more = false));
    },
  },
  components: { Filters, ProjectTable },
};
</script>

<style lang="scss" scoped></style>
