var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items,"empty-text":_vm.emptyText},scopedSlots:_vm._u([{key:"total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secToHuman")(item.summary.total || 0," hours", " minutes"))+" ")])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("ucwords")(item.name)))])]}},{key:"site",fn:function(ref){
var item = ref.item;
return [(item.site)?_c('span',[_vm._v(_vm._s(item.site.name))]):_c('span',[_vm._v("-")])]}},{key:"data_center",fn:function(ref){
var item = ref.item;
return [(item.data_center)?_c('span',[_vm._v(_vm._s(item.data_center.name))]):_c('span',[_vm._v("-")])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"LL")))])]}},{key:"users",fn:function(ref){
var item = ref.item;
return [(item.summary.users.length)?_c('avatars',{attrs:{"size":"40","users":item.summary.users,"count":5}}):_c('span',[_vm._v("No members assigned")])]}},{key:"admin",fn:function(ref){
var item = ref.item;
return [(item.admin)?_c('avatar',{attrs:{"size":"40","user":item.admin,"with-name":""}}):_c('span',[_vm._v("No admin assigned")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.navigateProject(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" View Project ")],1)],1),(_vm.$can.and('manage_projects'))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.emit('edit', item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-clipboard-edit-outline ")]),_vm._v(" Edit Project ")],1)],1):_vm._e(),(_vm.$can.and('manage_projects'))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.emit('delete', item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v(" Delete Project ")],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }