<template>
  <app-table
    :loading="loading"
    :headings="headers"
    :items="items"
    :empty-text="emptyText"
  >
    <template v-slot:total="{ item }">
      <span>
        {{ item.summary.total || 0 | secToHuman(" hours", " minutes") }}
      </span>
    </template>

    <template v-slot:name="{ item }">
      <span>{{ item.name | ucwords }}</span>
    </template>

    <template v-slot:site="{ item }">
      <span v-if="item.site">{{ item.site.name }}</span>
      <span v-else>-</span>
    </template>

    <template v-slot:data_center="{ item }">
      <span v-if="item.data_center">{{ item.data_center.name }}</span>
      <span v-else>-</span>
    </template>

    <template v-slot:created_at="{ item }">
      <span>{{ item.created_at | format("LL") }}</span>
    </template>

    <template v-slot:users="{ item }">
      <avatars
        v-if="item.summary.users.length"
        size="40"
        :users="item.summary.users"
        :count="5"
      ></avatars>
      <span v-else>No members assigned</span>
    </template>

    <template v-slot:admin="{ item }">
      <avatar v-if="item.admin" size="40" :user="item.admin" with-name></avatar>
      <span v-else>No admin assigned</span>
    </template>

    <template v-slot:actions="{ item }">
      <icon-list icon="mdi-dots-horizontal">
        <v-list dense>
          <v-list-item @click.stop="navigateProject(item)">
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-eye-circle-outline </v-icon>
              View Project
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click.stop="emit('edit', item)"
            v-if="$can.and('manage_projects')"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-clipboard-edit-outline </v-icon>
              Edit Project
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click.stop="emit('delete', item)"
            v-if="$can.and('manage_projects')"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-trash-can-outline </v-icon>
              Delete Project
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </icon-list>
    </template>
  </app-table>
</template>

<script>
import moment from "moment";
export default {
  props: {
    loading: { type: Boolean, default: false },
    loadingMore: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    emptyText: { type: String, default: "No data" },
  },
  data() {
    return {
      headers: [
        {
          text: "Project Name",
          value: "name",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Office",
          value: "site",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Data Center",
          value: "data_center",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Admin",
          value: "admin",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Users",
          value: "users",
          sortable: false,
          tdClass: "xtext-left",
        },
        {
          text: "Total Time Spent",
          value: "total",
          sortable: false,
          tdClass: "xtext-left",
        },
        {
          text: "Created",
          value: "created_at",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          tdClass: "xtext-right",
        },
      ],
    };
  },
  methods: {
    navigateProject(item) {
      this.$router.push({
        name: "app-project-monthly",
        params: { project_id: item.id, year_month: moment().format("YYYY-MM") },
      });
    },
    emit(action, item) {
      if (action === "edit") this.$event.$emit("edit-project", item);
      else if (action === "delete") this.$event.$emit("delete-project", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
